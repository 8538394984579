(() => {


  if (window.innerWidth > 768) {

    const container = document.querySelector('.component-thank-you');
    const contentContainer = container.querySelector(".component__content");
    const contentInner = contentContainer.querySelector(".component__content__inner");
    const gallery = container.querySelector('.gallery-thank-you');
    const signatures =  Array.from(contentContainer.querySelectorAll(".signatures img"));

    let active;

    const getGroupCenter = (group) => {
      const rect = group.getBoundingClientRect();
      return rect.height/2 + rect.y;
    }

    const removeActiveClasses = (el) => {
      const classes = el.className.split(" ").filter(c => !c.startsWith('active'));
      el.className = classes.join(" ").trim();
    }

    signatures.forEach(s => s.style.visibility = "hidden");

    const letterEls = Array.from(contentContainer.querySelectorAll(".component__content__inner > *:not(h2)"));
    let letterGroups = [];

    let letterGroup = [];
    for (let e=0;e<letterEls.length;e++) {
      const el = letterEls[e];
      if (el.tagName.toLowerCase() === "h3") {
        if (letterGroup.length) letterGroups.push(letterGroup);
        letterGroup = [el,el.nextElementSibling];
        e++;
        letterGroups.push(letterGroup);
        letterGroup = [];
      } else {
        letterGroup.push(el);
      }
    }
    letterGroups.push(letterGroup);


    let groupEls = [];
    letterGroups.forEach((group,g) => {
      const groupEl = document.createElement("div");
      groupEl.classList.add("group-thank-you");
      contentInner.insertBefore(groupEl,group[0]);
      group.forEach(el => {
        el.parentNode.removeChild(el);
        groupEl.appendChild(el);
      })
      groupEls.push(groupEl);

    })

    groupEls.forEach((g,i) => {
      let topOffset;
      if (groupEls[i-1]) {
        const rect = g.getBoundingClientRect();
        const oRect = groupEls[i-1].getBoundingClientRect();
        topOffset = -1 * (rect.top - 1 - (oRect.top + oRect.height / 2)) + "px";
      } else {
        topOffset = `-${g.getBoundingClientRect().height / 2}px`;
      }
      gsap.timeline({
        scrollTrigger:{
          trigger: g,
          start: `${topOffset} center`,
          end: `center center`,
          pin: false,
          scrub: true,
          snap: {
            snapTo:1,
            duration:.4,
            delay:0
          },
          anticipatePin:1,
          pinSpacing:false,
          onEnter: () => {
            removeActiveClasses(container);
            container.classList.add(`active-${i}`);
            if (i === groupEls.length-1) signatures.forEach(s => s.style.visibility = "visible");
          },
          onEnterBack: () => {
            removeActiveClasses(container);
            container.classList.add(`active-${i === 0 ? i : i-1}`);
          }
        }
      })
    })


    ScrollTrigger.create({
      trigger: container,
      pin: gallery,
      start: 'top top',
      end: 'bottom bottom',
      anticipatePin: 1,
      pinSpacing: true,
    });

  }

})()
